@font-face {
  font-family: "NB Architekt";
  src: url("/fonts/NB-Architekt.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "eurostile-bold-extended";
  src: url("/fonts/EurostileLTStd-BoldEx2.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Karelia Web';
  src: url('/fonts/KareliaWeb-Regular.woff2') format('woff2'),
       url('/fonts/KareliaWeb-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Karelia Web';
  src: url('/fonts/KareliaWeb-RegularItalic.woff2') format('woff2'),
       url('/fonts/KareliaWeb-RegularItalic.woff') format('woff');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Karelia Web';
  src: url('/fonts/KareliaWeb-Medium.woff2') format('woff2'),
       url('/fonts/KareliaWeb-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Karelia Web';
  src: url('/fonts/KareliaWeb-MediumItalic.woff2') format('woff2'),
       url('/fonts/KareliaWeb-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Karelia Web';
  src: url('/fonts/KareliaWeb-Bold.woff2') format('woff2'),
       url('/fonts/KareliaWeb-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Karelia Web';
  src: url('/fonts/KareliaWeb-BoldItalic.woff2') format('woff2'),
       url('/fonts/KareliaWeb-BoldItalic.woff') format('woff');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Karelia Web';
  src: url('/fonts/KareliaWeb-Black.woff2') format('woff2'),
       url('/fonts/KareliaWeb-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Karelia Web';
  src: url('/fonts/KareliaWeb-BlackItalic.woff2') format('woff2'),
       url('/fonts/KareliaWeb-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Engraving';
  src: url('/fonts/SFCompactRounded-Medium.woff2') format('woff2'),
       url('/fonts/SFCompactRounded-Medium.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'EngravingDruk';
  src: url('/fonts/DrukTextWide-Medium.woff') format('woff'),
    url('/fonts/DrukTextWide-Medium.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

/**
 * @license
 * MyFonts Webfont Build ID 3867246, 2020-12-16T11:57:38-0500
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: TT Mussels Regular by TypeType
 * URL: https://www.myfonts.com/products/regular-tt-mussels-252110
 * Copyright: Copyright © 2023 Monotype Imaging Inc. All rights reserved.
 *
 * © 2023 MyFonts Inc. */
 @font-face {
  font-family: "Mussels";
  src: url("/fonts/TTMusselsRegular.woff2") format("woff2"),
       url("/fonts/TTMusselsRegular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

/**
 * @license
 * MyFonts Webfont Build ID 3867246, 2020-12-16T11:57:38-0500
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: TT Mussels DemiBold by TypeType
 * URL: https://www.myfonts.com/products/demi-bold-tt-mussels-252149
 * Copyright: Copyright © 2023 Monotype Imaging Inc. All rights reserved.
 *
 * © 2023 MyFonts Inc. */
@font-face {
  font-family: "Mussels";
  src: url("/fonts/TTMusselsDemiBold.woff2") format("woff2"),
       url("/fonts/TTMusselsDemiBold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}
