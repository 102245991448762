@import "_mixins";
@import "_typography";
@import "_swiper";
// @import "node_modules/@glidejs/glide/src/assets/sass/glide.core";

@tailwind base;

@tailwind components;

@tailwind utilities;

html {
  // min-height: 100%;
  @apply font-sans;
  text-size-adjust: 100%;
  font-weight: 400;
  /* Font variant */
  // font-feature-settings: 'kern', 'liga', 'dlig', 'hlig', 'cswh';
  /* Smoothing */
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
}

html, body {
  height: 100%;

  @apply bg-black;
  @apply text-white;
}

.container {
  @apply pl-4;
  @apply pr-4;
}

table {
  th {
    @apply p-3;
  }

  td {
    @apply border-gray-300;
    @apply border-t;
    @apply p-3;
  }
}

input::placeholder {
  @apply text-gray-300;
}

.content {
  p {
    @apply mb-4;
  }

  ul {
    @apply ml-6;
    @apply mb-6;
    @apply p-0;

    li {
      position: relative;
      list-style: disc;
    }
  }

  a {
    @apply underline;
  }
}

.loading {
  color: transparent !important;
  pointer-events: none;
  position: relative;

  @at-root &.default-button--inverted {
    &::after {
      @include loader(#fff);
    }
  }

  &::after {
    @include loader(#fff);
    top: 50%;
    left: 50%;
    margin-left: -0.5em;
    margin-top: -0.5em;

    position: absolute !important;
  }
}

#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: #4299e1;

  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;

  width: 100%;
  height: 2px;
}

/* Fancy blur effect */
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px #4299e1, 0 0 5px #4299e1;
  opacity: 1.0;

  -webkit-transform: rotate(3deg) translate(0px, -4px);
      -ms-transform: rotate(3deg) translate(0px, -4px);
          transform: rotate(3deg) translate(0px, -4px);
}


.origin-top-right {
  transform-origin: top right;
}
.transition-all {
  transition-property: all;
}
.transition-fastest {
  transition-duration: 50ms;
}
.transition-faster {
  transition-duration: 100ms;
}
.transition-fast {
  transition-duration: 150ms;
}
.transition-medium {
  transition-duration: 200ms;
}
.ease-out-quad {
  transition-timing-function: cubic-bezier(.25, .46, .45, .94);
}
.ease-in-quad {
  transition-timing-function: cubic-bezier(.55, .085, .68, .53);
}
.scale-70 {
  transform: scale(.7);
}
.scale-100 {
  transform: scale(1);
}

.h-cover {
  height: calc(var(--vh, 1vh)*100);
}

/*! purgecss start ignore */
.glide {
  &__bullets {
    display: flex;
    align-items: center;
  }

  &__bullet {
    display: block;
    width: 15px;
    height: 3px;
    @apply bg-gray-mm-400;
    @apply mr-1;

    @media(min-width: theme('screens.sm')) {
      width: 25px;
      height: 5px;
      @apply mr-2;
    }

    &--active {
      background: white;
    }
  }

  &__controls {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    z-index: 2;
  }

  &__arrow {
    position: relative;
    appearance: none;
    display: block;
    border: 0;
    margin: 0;
    padding: 0;
    width: calc(50% - 10px);
    height: 100%;
    transition: all 0.5s ease;
    cursor: pointer;
    background: transparent;

    &:focus {
      outline: 0;
    }

    &--left {
      @media(max-width: theme('screens.sm')) {
        background: url('/images/icons/arrow-left.svg') left center no-repeat;
        margin-left: 10px;
      }
      cursor: url('/images/icons/arrow-left.svg'), auto;
    }

    &--right {
      @media(max-width: theme('screens.sm')) {
        background: url('/images/icons/arrow-right.svg') right center no-repeat;
        margin-right: 10px;
      }
      cursor: url('/images/icons/arrow-right.svg'), auto;
    }
  }
}
/*! purgecss end ignore */

label {
  &:focus-within {
    @apply shadow-outline;
  }
}

.checkbox {
  .marker {
    display: block;
    width: 24px;
    height: 24px;
    background: transparent;
    border: 1px solid #fff;
    border-radius: 2px;
  }

  input:checked+label {
    .checkbox__icon {
      opacity: 1;
    }
    .marker {
      background: #fff url('/images/icons/checkmark.svg') center center no-repeat;
    }
  }
}

.radio-icon {
  flex-shrink: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 26px;
  height: 26px;
  @apply border;
  @apply border-white;
  @apply rounded-full;
  text-align: center;

  &--checked {
    &:before {
      content: "";
      display: inline-block;
      height: 12px;
      width: 12px;
      @apply bg-white;
      @apply rounded-full;
    }
  }
}

.clip {
  position: fixed;
  position: absolute;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
}

.aspect-ratio-16x10 {
  position: relative;
  &:before {
    padding-bottom: 62.5%;
    content: "";
    display: block;
  }
}

.aspect-ratio-16x9 {
  position: relative;
  &:before {
    padding-bottom: 56.25%;
    content: "";
    display: block;
  }
}

.aspect-ratio-360x440 {
  position: relative;
  &:before {
    padding-bottom: 122.2%;
    content: "";
    display: block;
  }
}

.aspect-ratio-1x1 {
  position: relative;
  &:before {
    padding-bottom: 100%;
    content: "";
    display: block;
  }
}

.invoice-dl {
  dl {
    display: flex;
    flex-flow: row wrap;
  }
  dt {
    flex-basis: 50%;
    @apply border-b-2;
    @apply border-gray-mm-600;
    @apply py-4;
  }
  dd {
    flex-basis: 50%;
    flex-grow: 1;
    @apply pl-3;
    @apply py-4;
    @apply text-right;
    @apply border-b-2;
    @apply border-gray-mm-600;
  }
}

.features-grid {
  color: #444;
  position: relative;
  display: grid;
  grid-gap: 10px;
  grid-auto-flow: row;
  grid-template-columns: repeat(2, 1fr);

  @media(min-width: theme('screens.sm')) {
    display: grid;
    grid-gap: 10px;
    grid-auto-flow: row;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(12, 75px);
  }

  .feature {
    @apply bg-gray-mm-600;
    color: #fff;
    border-radius: 5px;
  }

  @media(min-width: theme('screens.sm')) {
    .slot-1 {
      grid-column: 1 / 2;
      grid-row: 1 / 4;
    }

    .slot-2 {
      grid-column: 1 / 2;
      grid-row: 4 / span 9;
    }

    .slot-3 {
      grid-column: 2 / 3;
      grid-row: 1 / 5;
    }

    .slot-4 {
      grid-column: 2 / 3;
      grid-row: 5 / 9;
    }

    .slot-5 {
      grid-column: 2 / 3;
      grid-row: 9 / -1;
    }

    .slot-6 {
      grid-column: 3 / span 2;
      grid-row: 1 / 7;
    }

    .slot-7 {
      grid-column: 3 / span 1;
      grid-row: 7 / span 6;
    }

    .slot-8 {
      grid-column: 4 / span 1;
      grid-row: 7 / span 4;
    }

    .slot-9 {
      grid-column: 4 / span 1;
      grid-row: 11 / span 2;
    }
  }
}

.feature-card {
  position: relative;
}

.footer-bg {
  background: url('/images/tmbn/theme-bottom-mob.jpg') center center no-repeat;
  background-size: cover;

  @media(min-width: theme('screens.sm')) {
    background: url('/images/tmbn/theme-bottom.jpg') center center no-repeat;
    background-size: cover;
  }
}

.accordion {
  .accordion-content a {
    text-decoration: underline;
  }

  .arrow {
    background: url('/images/icons/arrow-down.svg') center center no-repeat;
    background-size: 100% 100%;
    display: block;
    width: 16px;
    height: 16px;
    transition: all 350ms ease;

    &.is-open {
      transform: rotate(180deg);
    }
  }

  &--inverted {
    .arrow {
      background: url('/images/icons/arrow-down-white.svg') center center no-repeat;
    }
  }
}

/*! purgecss start ignore */

.slide-up-enter-active {
  transition: all .5s;
  transition-delay: 200ms;
}

.slide-up-leave-active {
  display: none;
}

.slide-up-enter {
  opacity: 0;
  transform: translateY(-20px);
}

.fade-enter-active {
  transition: all 300ms ease;
}
.fade-leave-active {
  transition: all 300ms cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.fade-enter, .fade-leave-to {
  // transform: translateX(10px);
  opacity: 0;
}

.tabs > div {
  position: relative;
  transition: all .3s cubic-bezier(0.535, 0.000, 0.340, 0.795);
}

.slide-left-enter, .slide-right-leave-to {
  opacity: 0;
  transform: translate(10px, 0);
}
.slide-left-leave-to, .slide-right-enter {
  opacity: 0;
  transform: translate(-10px, 0);
}
/*! purgecss end ignore */

.range-wrapper {
  display: block;
  height: 20px;
  position: relative;
  width: 120px;

  &:after {
    position: absolute;
    top: 50%;
    margin-top: -1px;
    content: "";
    display: block;
    height: 2px;
    width: 100%;
    background: white;
  }
}

.slider {
  -webkit-appearance: none;  /* Override default CSS styles */
  appearance: none;
  height: 20px;
  background: transparent;
  outline: none;
  position: relative;
  width: 100%;
}

/* Mouse-over effects */
.slider:hover {
  opacity: 1; /* Fully shown on mouse-over */
}

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  background: white;
  cursor: pointer;
  z-index: 1;
  border-radius: 100px;
}

.slider::-moz-range-thumb {
  width: 20px;
  height: 20px;
  background: white;
  cursor: pointer;
  z-index: 1;
  border-radius: 100px;
}

/*! purgecss start ignore */
.modal {
  &-content {
    width: 720px;
  }

  &-prev {
    position: absolute;
    top: 310px;
    left: 0;
  }

  &-next {
    position: absolute;
    top: 310px;
    right: 0;
  }

  .slide-left-enter, .slide-right-leave-to {
    opacity: 0;
    transform: translate(10px, 0);
  }
  .slide-left-leave-to, .slide-right-enter {
    opacity: 0;
    transform: translate(-10px, 0);
  }
}

.renderer {
  .engraving {
    text {
      text-transform: uppercase;
      font-style: normal;
      font-weight: normal;
    }

    &--dark {
      text {
        fill: rgb(20, 20, 21);
      }
    }
  }
}

.renderer-inner {
  position: relative;
  transform-origin: 0% 0%;
  padding-bottom: 100%;
  .engraving {
    position: absolute;
    top: 0px;
    left: 0px;
    max-width: 100%;
    max-height: 100%;
  }
  .image {
    position: absolute;
    top: 0px;
    left: 0px;
    img {
      display: block;
    }
  }
}

.icon-magnifier {
  background-image: url('/images/icons/magnifier.png');
}

.swiper-container {
  min-height: 300px;
}

.swiper-container-horizontal > .swiper-scrollbar {
  width: 100%;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.swiper-container-horizontal > .swiper-scrollbar.swiper-scrollbar-after {
  margin: 40px auto 0;
  position: relative;
  width: 100%;
  left: auto;
}

.swiper-scrollbar-drag {
  background: #fff;
}

.swiper-scrollbar-dark .swiper-scrollbar-drag {
  background: #aeaeae;
}

.swiper-slide {
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Sales/Index.vue */
#page-sales {
  @apply font-mussels;

  #main-column {
    width: 100%;
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
  }

  .bounce {
    -moz-animation: bounce 3s infinite;
    -webkit-animation: bounce 3s infinite;
    animation: bounce 3s infinite;
  }

  @keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-10px);
    }
    60% {
      transform: translateY(-7px);
    }
  }

  #scroll-prompt {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    text-align: center;
    z-index: 9999;
    pointer-events: none;
    img {
      z-index: 9999;
      width: 32px;
      position: absolute;
      bottom: 1rem;
      left: 50%;
      margin-left: -16px;
    }
    transition: opacity 1s;
    &.fading {
      opacity: 0;
    }
  }

  .box {
    margin-bottom: 1rem;
    @screen lg {
      margin-bottom: 1.25rem;
    }
    &.rounded {
      border-radius: 1rem;
    }
    &.black {
      background-color: black;
      color: white;
    }
    &.white {
      background-color: white;
      color: black;
    }
    &.grey {
      background-color: #13181F;
      color: white;
    }
    &.padded {
      padding: 2rem 1rem;
    }
    overflow: hidden;
    background-clip: padding-box;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    .box {
      margin-bottom: 0px;
    }
  }

  #intro-box {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0px;
    left: 0px;
    picture,img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  #intro-ghost {
    height: 100vh;
  }
  #intro-text {
    flex-direction: column;
    &.titles-alignment-bottom {
      flex-direction: column-reverse;
    }
    #titles {
      flex: 1;
    }
    .pad {
      flex: 1;
    }
  }

  #slide-over {
    position: relative;
    padding-bottom: 2rem;
    border-radius: 1rem 1rem 0 0;
    background-color: #383838;
    @screen lg {
      border-radius: 0;
      padding-top: 3rem;
    }
  }

  #top-grid {
    display: grid;
    column-gap: 1rem;
    grid-template-columns: 1fr;
    @screen lg {
      column-gap: 1.25rem;
      grid-template-columns: 1fr 1fr;
    }
  }

  #visualization-grid {
    display: grid;
    column-gap: 1rem;
    grid-template-columns: 1fr;
    @screen lg {
      column-gap: 1.25rem;
      grid-template-columns: 1fr 1fr;
    }
    #internals-box {
      background-color: #eae9ea;
      color: #A7A7A7;
      h2 {
        color: black;
      }
    }
    #straps-box {
      position: relative;
      #straps-more {
        position: absolute;
        left: 0px;
        right: 0px;
        bottom: 1rem;
        text-align: center;
        text-decoration: underline;
      }
    }
    #turntable-box {
      position: relative;
      #turntable-more {
        color: #616161;
        position: absolute;
        left: 0px;
        right: 0px;
        bottom: 1rem;
        text-align: center;
        text-decoration: underline;
      }
    }
    #turntable-box, #straps-box {
      @screen lg {
        .text-2xl {
          margin-top: 1rem;
        }
      }
      position: relative;
      .open-button {
        position: absolute;
        top: 10px;
        right: 10px;
        &:hover {
          opacity: 0.9;
        }
      }
    }
  }

  #shop-box {
    text-align: center;
    font-family: 'Karelia Web';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    .content {
      padding-bottom: 2rem;
    }
    #shop-button {
      padding-left: 3rem;
      padding-right: 3rem;
      padding-top: .5rem;
      padding-bottom: .7rem;
      margin-bottom: 3rem;
    }
    .price-alternative {
      font-size: 12px;
    }
    .subtitle {
      opacity: 0.6;
    }
    .terms-list {
      margin-top: 1rem;
      margin-left: 1rem;
      margin-right: 1rem;
      text-align: left;
      font-size: 10px;
      .row {
        padding: .5rem 0;
        display: flex;
        flex-direction: row;
        border-bottom: 2px solid #BABABA;
        .label {
          flex: 1;
        }
      }
    }
  }

  #specifications-box {
    .desktop {
      display: none;
    }
    @screen lg {
      background: none;
      .mobile {
        display: none;
      }
      .desktop {
        position: relative;
        display: grid;
        grid-auto-columns: 1fr;
        gap: 1rem;
        .box {
          grid-row: 1;
        }
        .label {
          color: white;
          position: absolute;
          z-index: 1000;
          top: 1rem;
          left: 1rem;
          font-family: 'Karelia Web';
          font-style: normal;
          font-weight: 600;
          font-size: 22px;
        }
      }
    }
  }

  #timepiece-box {
    .copy {
      .inner {
      }
      flex: 1;
    }

    .image-wrap {
      position: relative;
      img {
        display: block;
        width: 100%;
      }
      svg {
        cursor: pointer;
        position: absolute;
        width: 20%;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        &:hover {
          opacity: 0.9;
        }
      }
    }

    @screen lg {
      .outer {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
      }
      .image-wrap {
        flex: 1.8;
      }
    }
  }

  #design-details-box {
    padding-top: 2rem;
    padding-bottom: 1rem;
    .top {
      padding: 0 1rem;
      .title {
        display: flex;
        flex-direction: row;
        h2 {
          flex: 1;
        }
      }
      .subtitle {
        max-width: 800px;
      }
    }
    .controls {
      display: flex;
      flex-direction: row;
      margin-bottom: 1rem;
    }
    .arrow-nav {
      position: static;
      img {
        filter: brightness(0);
      }
    }
    .swiper-pagination-bullets {
      flex: 1;
    }
    .swiper-pagination-bullet {
      background-color: black;
      margin-right: 0.5rem;
    }
    .swiper-pagination-active {
      background-color: black;
      opacity: 0.4;
    }

    .desktop {
      display: none;
    }
    @screen lg {
      .mobile {
        display: none;
      }
      .desktop {
        margin: 0 1rem;
        display: grid;
        grid-auto-columns: 1fr;
        gap: 1rem;
        .slide {
          grid-row: 1;
        }
      }
    }
  }

  #facebook-box {
    font-family: 'Karelia Web';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    text-align: center;
    .title {
      color: white;
    }
    .content {
      color: #818181;
    }
  }


  .responsive-image-slider {
    position: relative;
    .label {
      color: white;
      position: absolute;
      z-index: 1000;
      top: 1rem;
      left: 1rem;
      font-family: 'Karelia Web';
      font-style: normal;
      font-weight: 600;
      font-size: 22px;
      line-height: 24px;
    }
    .swiper-pagination-bullets {
      position: absolute;
      left: 0px;
      right: 0px;
      bottom: 1rem;
      z-index: 1000;
      text-align: center;
    }
    .swiper-pagination-bullet {
      background-color: #C4C4C4;
      margin-right: 0.5rem;
    }
    .swiper-pagination-active {
      background-color: #C4C4C4;
      opacity: 0.4;
    }
  }

  .pagination {
    position: absolute;
    bottom: 1rem;
    left:  1rem;
    z-index: 1000;
  }

  .arrow-nav {
    position: absolute;
    top: 1rem;
    right:  1rem;
    z-index: 1000;
    display: flex;
    flex-direction: row;
    .swiper-arrow-prev {
      margin-right: 4px;
    }
    img {
      user-select: none;
    }
    div {
      &.swiper-button-disabled {
        opacity: 0.4;
      }
    }
  }

}


/* Sales/Boxes/IntroText.vue */
#intro-text {
  .container {
    position: relative;
    @screen lg {
      display: flex;
      flex-direction: row;
    }
    .copy {
      .inner {
        max-width: 380px;
        @screen lg {
          text-align: center;
          margin-left: auto;
          margin-right: auto;
        }
      }
      flex:1.3;
    }
    .accordion {
      margin-top: 2rem;
      .hero-question {
        div {
          font-size: 1rem !important;
        }
      }
      text-align: left;
    }
    .hero-question {
      display: flex;
      flex-direction: row;
      img {
        margin-right: 1rem;
      }
    }
  }
}

/* Sales/Slices/IntroSpecs.vue */
#intro-specs {
  .container {
    position: relative;
    @screen lg {
      display: flex;
      flex-direction: row;
    }
    .copy {
      .inner {
        max-width: 380px;
      }
      flex:1.3;
    }
    .specs {
      display: flex;
      flex-direction: row;
      flex: 1;
      &-copy {
        flex: 1.2;
      }
      &-watch {
        flex: 1;
        align-self: center;
        .img {
          display: block;
          width: 100%;
        }
      }
    }
  }
}


.range-sequence {
  .sequence {
    margin-bottom: 1rem;
  }
  .label {
    position: relative;
    top: -.15rem;
  }
  .toggle-timeline {
    padding: 0 1rem;
  }
}

.toggle-timeline {
  display: flex;
  flex-direction: row;
  align-items: center;

  .track {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 2rem;
    flex: 1;
    margin: 0 1.8rem;

    .line {
      position: absolute;
      left: 0rem;
      right: 0rem;
      top: 0.875rem;
      height: 0.25rem;
      background-color: white;
    }

    .thumb {
      display: block;
      position: relative;
      left: 3rem;
      z-index: 1;
      user-drag: none;
      user-select: none;
      -webkit-user-drag: none; /* Prevents dragging of images/divs etc */
      &.large {
        top: .02rem;
      }
    }
  }

  button {
    border: none;
    width: fit-content;
    background: none;
    margin: 0px;
    padding: 0px;
  }
}

.sales-modal {
  background-color: #060606;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 8000;

  // Scrollable, but no scrollbar
  overflow-y: scroll;
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
  &::-webkit-scrollbar {
      display: none;  /* Safari and Chrome */
  }
}

#titles {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  @screen lg {
    font-size: 20px;
  }
  &-title {
    font-size: 24px;
    padding-top: .22rem;
    margin-bottom: .26rem;
    @screen lg {
      font-size: 40px;
    }
  }
}

#more-info {
  padding-top: 4rem;
  padding-bottom: 5.6rem;
  .copy {
    margin-bottom: 2.4rem;
  }
  &-menu {
    font-weight: 400;
    font-size: 20px;
    margin: 0 auto;
    max-width: 330px;
  }
}

#more-info-modal-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #979797;
  figure {
    display: block;
  }
  .top-image {
    img {
      max-height: 60vh;
    }
  }
  .space {
    flex: 1;
  }
  .bottom-image {
    img {
      max-height: 60vh;
    }
  }
  .copy {
    margin-top: 4rem;
    margin-bottom: 4rem;
    max-width: 700px;
    text-align: center;
  }
  h2 {
    color: white;
    margin-bottom: 2rem;
  }
}

#straps-modal {
  max-width: 700px;
}

#turntable-modal {
  max-width: 600px;
  .turntable {
    margin-top: 2rem;
  }
}

#timepiece-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

#footer-v2-box {
  .footer-v2-bg {
    position: absolute;
    inset: 0;
    background: #E0BC0E url("/images/sales/footer-v2-bg.jpg") center bottom no-repeat;
    background-size: contain;
  }
}

#auth-countdown {
  .countdown-bg {
    width: 100%;
    height: 455px;
    background: url("/images/sales/hero_desktop_comp.png") center center no-repeat;
    box-shadow: inset 0 0 0 1000px rgba(217, 217, 217, .2);
    background-size: cover;
    @screen lg {
      height: 55vh;
    }
  }

}
#logos-box {
  .shadow {
    position: absolute;
    inset-inline: 0;
    z-index: 10;
    bottom: 0;
    height: 120px;
    background: linear-gradient(180deg, rgba(255, 211, 0, 0) 0%, #FFD300 100%);

    @screen sm {
      height: 106px;
      background: linear-gradient(180deg, rgba(255, 211, 0, 0) 0%, #FFD300 100%);
    }
    @screen lg {
      height: 155px;
    }
  }
  .accordion .arrow {
    width: 40px;
    transform: translateX(-50%);
  }
  .open {
    height: 100%;
  }
  .closed {
    height: 160px;

    @screen sm {
      height: 180px;
    }
    @screen md {
      height: 234px;
    }
  }
}

.video-container iframe,
.video-container object,
.video-container img,
.video-container figure,
.video-container video {
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

/*! purgecss end ignore */
